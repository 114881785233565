@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'),
       url('./fonts/Gilroy-Light.otf') format('otf'),
       url('./fonts/Gilroy-ExtraBold.otf') format('otf');
}




* {
  margin: 0;
  font-family: "Gilroy", sans-serif;
  box-sizing: border-box;
}



:root {
  --main-color: #0044C5;
}
.App {
  text-align: center;
  max-width: 500px;
  padding: 0 25px;
  height: 100vh;  
  margin: 0 auto;
}
.App_back {
  text-align: center;
  max-width: 500px;
  padding: 0 25px;
  height: 100vh;  
  margin: 0 auto;
  background-image: url("./images/Background.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.brand_img {
    padding: 15vh 0 10vh 0;
}

.input_container {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  border-bottom: 1px solid rgba(128, 163, 230, 0.6);
  padding: 5px 10px;
}
.input_container input {
  border: 0;
  color: var(--main-color);
  opacity: 0.6;
  font-size: 16px;
}
input:focus {
  outline: none;
  border: 0;
}
.oranged {
  border-bottom: 2px solid #FFD748 !important;
}
.ant-input-affix-wrapper {
  border: 0 !important;
  font-size: 16px;
  padding: 0 !important;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: blue !important;
  opacity: .85 !important; /* Firefox */
}
.mybutton {
    background-color: var(--main-color);
    font-size: 16px;
    padding: 12px 45px;
    width: 100%;
    border-radius: 6px;
    cursor: pointer;
    color: white;
}
.ant-input {
  padding: 0px 5px !important;
}
.single {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  border-bottom: 1px solid rgba(128, 163, 230, 0.6);
  padding: 5px 10px;
}

.single input {
  border: 0;
  color: var(--main-color);
  opacity: 0.6;
  font-size: 16px;
}
.ant-input-suffix svg {
  color: rgba(128, 163, 230, 1) !important;
}
.react-ripples {
  width: 100%;
}


/*  Sign up  */

.signup {
  padding: 20px 0px;
  overflow: auto;
}


.ant-input:focus, .ant-input-focused {
  box-shadow: none !important;
}

.back_icon {
  text-align: left;
  cursor: pointer;
}

.text {
  text-align: left;
  font-size: 10px;
  color: var(--main-color);
}

.triple {
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  align-items: center;
  border-bottom: 1px solid rgba(128, 163, 230, 0.6);
  padding: 5px 10px;
}

.triple input{
  border: 0;
  color: var(--main-color);
  opacity: 0.6;
  font-size: 16px;
}

.ant-select-selector {
  border: none !important;
  text-align: left;
  padding: 0 3px !important;
  color: var(--main-color);
}
.ant-select-selection-placeholder {
  color: var(--main-color) !important;
  font-size: 16px;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  box-shadow: none !important;
}
input {
  background-color: transparent !important;
}


/* cashback */
.cashback {
  padding: 40px 0;
}

.cashback_area {
  padding: 80px 60px 40px 60px;
  background-color: white;
  border-radius: 12px;
  position: relative;
  margin-top: 14vh;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
}
.avatar {
  position: absolute;
  top: -50px;
  left: 50%;
  width: 104px;
  height: 104px;
  transform: translateX(-50%);
}

.ant-picker {
  padding: 0 !important;
}